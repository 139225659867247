import React, { Suspense } from "react";
import { hot } from "react-hot-loader";
import Login from "./Login";
import { CountingSheepWebContexts } from "@countingsheep/contexts";
import "./app.css";
import Config from "../config";
import { Route, Switch } from "wouter";
import { Logout } from "./Logout";

function App() {
  return (
    <Switch>
      <Route path="/logout/:continue">
        <Logout />
      </Route>
      <Route path="/logout">
        <Logout />
      </Route>
      <Route>
        <Login />
      </Route>
    </Switch>
  );
}

function AppRoot() {
  return (
    <Suspense fallback={"Loading..."}>
      <CountingSheepWebContexts
        firebaseConfig={{
          apiKey: "AIzaSyCXVR9se4xYhfJ_F-ssTwXnqmcOzu2PY98",
          authDomain: "counting-sheep-app.firebaseapp.com",
          databaseURL: "https://counting-sheep-app.firebaseio.com",
          projectId: "counting-sheep-app",
          storageBucket: "counting-sheep-app.appspot.com",
          messagingSenderId: "51103647054",
          appId: "1:51103647054:web:86f1e9c7b43e665ec70a1f",
          measurementId: "G-STLS5JN07X",
        }}
        config={{
          tokenSwapUrl: Config.TOKEN_SWAP_URL,
        }}
      >
        <App />
      </CountingSheepWebContexts>
    </Suspense>
  );
}

declare let module: Record<string, unknown>;

export default hot(module)(AppRoot);
