import React from "react";
import { Loading, Redirect } from "@countingsheep/components-web";
import { useAuth } from "@countingsheep/contexts";

export function Logout() {
  const { signOut } = useAuth();
  const [redirect, setRedirect] = React.useState(false);

  React.useEffect(() => {
    if (!redirect) {
      console.log("SIGNIONG OUT");
      signOut();
      setRedirect(true);
    } else {
      console.log("Redirect is true");
    }
  }, []);

  console.log("hereiam");
  return redirect ? <Redirect to={"/"} /> : <Loading />;
}
