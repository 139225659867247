import * as React from "react";
import { setPageTitle } from "../utils";
import { FirebaseLogin } from "./FirebaseLogin";
import {
  useAuth,
  useFirebase,
  useIsAuthenticated,
} from "@countingsheep/contexts";
import { Redirect } from "@countingsheep/components-web";
import Config from "../config";
import { LoginLoading } from "./LoginLoading";

const Login: React.FC<{ children?: React.ReactNode }> = () => {
  React.useEffect(() => {
    setPageTitle("Login");
  }, []);
  const [signingIn, setSigningIn] = React.useState(false);
  const [initialLoading, setInitialLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | undefined>(undefined);
  const [redirect, setRedirect] = React.useState<string>(undefined);
  const [shouldRedirect, setShouldRedirect] = React.useState(true);

  const isAuthenticated = useIsAuthenticated();
  const { signOut } = useAuth();
  const { auth: firebaseAuth } = useFirebase();

  React.useEffect(() => {
    setRedirect(Config.DEFAULT_REDIRECT);
  }, [Config.DEFAULT_REDIRECT]);

  React.useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("continue")) {
      console.log(urlParams.get("continue"), Config.BASE_URL);
      setRedirect(
        `${new URL(urlParams.get("continue"), Config.BASE_URL).toString()}`,
      );
    }
    if (urlParams.has("skipRedirect")) {
      setShouldRedirect(false);
    }
    if (window.location.pathname.includes("logout")) {
      setShouldRedirect(false);
    }
    // if (urlParams.has("from")) {
    //   setShouldRedirect(false);
    // }
  }, [window.location.search]);

  const loading = signingIn || initialLoading;

  const handleLoginError = (e: Error) => {
    setError(e);
    setSigningIn(false);
  };

  const startSignOut = () => {
    firebaseAuth.signOut();
    signOut();
  };

  // if (loading) {
  //   return <Loading />;
  // }
  // if (error) {
  //   return <div>Error</div>;
  // }

  if (isAuthenticated === true && redirect && shouldRedirect) {
    return (
      // <div>
      //   {""} <button onClick={() => startSignOut()}>Sign-out</button>
      // </div>

      <Redirect
        to={redirect}
        beforeRedirect={async () => await firebaseAuth.signOut()}
      />
    );
  }

  if (isAuthenticated === undefined) {
    return <LoginLoading />;
  }

  return <FirebaseLogin />;
};

export default Login;
