import React from "react";

import { LoginFormWrapper } from "./LoginFormWrapper";
import { Loading } from "@countingsheep/components-web";

export function LoginLoading() {
  return (
    <LoginFormWrapper>
      <Loading />
    </LoginFormWrapper>
  );
}
