import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  EmailAuthProvider,
} from "firebase/auth";
import { useFirebase } from "@countingsheep/contexts";
import { LoginFormWrapper } from "./LoginFormWrapper";

export function FirebaseLogin() {
  const { auth } = useFirebase();

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // signInSuccessUrl: "/signedIn",
    signInOptions: [
      GoogleAuthProvider.PROVIDER_ID,
      FacebookAuthProvider.PROVIDER_ID,
      EmailAuthProvider.PROVIDER_ID,
    ],
    signInSuccessUrl: "/",
    callbacks: {
      signInSuccessWithAuthResult: (
        // tslint:disable-next-line:no-any firebase dependency not available.
        authResult: any,
        redirectUrl?: string,
      ): boolean => {
        console.log(authResult, redirectUrl);
        return true;
      },
    },
  };

  return (
    <LoginFormWrapper>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
    </LoginFormWrapper>
  );
}
