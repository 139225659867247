import { colours } from "@countingsheep/common/lib/styles";
import * as React from "react";
import BackgroundImage from "../assets/img/20180825_132216.webp";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import LogoImg from "../assets/img/logo512.png";

type Props = {
  children?: React.ReactNode;
};

export const LoginFormWrapper: React.FC<Props> = ({ children }) => {
  const isSmallScreen = useMediaQuery({
    query: "(max-width: 1100px)",
  });

  return (
    <Container>
      <ImageWrapper isSmallScreen={isSmallScreen}>
        <img
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          src={BackgroundImage}
        />
      </ImageWrapper>
      <LoginWapper isSmallScreen={isSmallScreen}>
        <LoginInputsWrapper>
          <LogoImage alt={"The Counting Sheep App"} src={LogoImg} />
          {children}
        </LoginInputsWrapper>
      </LoginWapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colours.darkPrimaryColour};
  display: flex;
`;

const ImageWrapper = styled.div<{ isSmallScreen: boolean }>`
  width: 65%;
  ${({ isSmallScreen }) =>
    isSmallScreen &&
    `
  display: none;
`}
`;

const LoginWapper = styled.div<{ isSmallScreen: boolean }>`
  width: ${(p) => (p.isSmallScreen ? 100 : 35)}%;
`;
const LoginInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15%;
`;
const LogoImage = styled.img`
  height: 120px;
  width: 120px;
  justify-content: center;
`;
